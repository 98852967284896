import { useEffect } from 'react'
import { gql, useMutation, useQuery } from '@apollo/client'
import getValue from 'lodash/get'
import { LOG_ENTRY_VIEW } from '../activity/activityQueries'

const PARTNER_DETAILS_VIEW = gql`
  fragment PartnerDetailsView on Partner {
    id
    name
    slug
    idsTenantId
    partnerAccountNumber
    accountReady
    supportInfo
    logoUrl
    logoUrlDark
    createdAt
    members {
      role
      user {
        id
        email
        confirmedAt
      }
    }
  }
`

const PARTNER_ORGANIZATION_VIEW = gql`
  fragment PartnerOrganizationView on Organization {
    id
    name
    slug
    createdAt
    propertiesCount
    logEntries(where: { actionType: { _eq: created_organization } }) {
      nodes {
        ...LogEntryView
      }
    }
  }
  ${LOG_ENTRY_VIEW}
`

export const getPartnerOrganizations = gql`
  query partnerOrganizations(
    $slug: String!
    $first: Int!
    $offset: Int!
    $orderBy: OrderByOrganization!
    $filters: PartnerOrganizationFilters
  ) {
    partnerOrganizations(
      slug: $slug
      first: $first
      offset: $offset
      orderBy: [$orderBy]
      filters: $filters
    ) {
      nodes {
        ...PartnerOrganizationView
      }
      recordCount
    }
  }
  ${PARTNER_ORGANIZATION_VIEW}
`

export const getPartner = gql`
  query partner($slug: String!) {
    partner(slug: $slug) {
      ...PartnerDetailsView
    }
  }
  ${PARTNER_DETAILS_VIEW}
`

export const deletePartnerMembers = gql`
  mutation deletePartnerMembers($userIds: [ID!]!, $partnerId: ID!) {
    deletePartnerMembers(userIds: $userIds, partnerId: $partnerId) {
      ...PartnerDetailsView
    }
  }
  ${PARTNER_DETAILS_VIEW}
`

export const updatePartnerMemberRole = gql`
  mutation updatePartnerMemberRole($userId: ID!, $partnerId: ID!, $role: PartnerRoleEnum!) {
    updatePartnerMemberRole(userId: $userId, partnerId: $partnerId, role: $role)
  }
`

export const updatePartner = gql`
  mutation updatePartner($partner: UpdatePartnerAttributes!) {
    updatePartner(partner: $partner) {
      partner {
        ...PartnerDetailsView
      }
      userErrors {
        message
      }
    }
  }
  ${PARTNER_DETAILS_VIEW}
`

export const uploadPartnerLogo = gql`
  mutation uploadPartnerLogo($partnerId: ID!, $file: Upload!, $isDark: Boolean) {
    uploadPartnerLogo(partnerId: $partnerId, file: $file, isDark: $isDark) {
      logoUrl
      userErrors {
        message
      }
    }
  }
`

export const deletePartnerLogo = gql`
  mutation deletePartnerLogo($partnerId: ID!, $isDark: Boolean) {
    deletePartnerLogo(partnerId: $partnerId, isDark: $isDark) {
      success
      userErrors {
        message
      }
    }
  }
`

export const addPartnerMember = gql`
  mutation addPartnerMembers($members: [InvitePartnerMembersInput!]!, $partnerId: ID!) {
    addPartnerMembers(members: $members, partnerId: $partnerId) {
      partner {
        ...PartnerDetailsView
      }
      userErrors {
        message
        path
      }
    }
  }
  ${PARTNER_DETAILS_VIEW}
`

const partnerUpdatedSubscription = gql`
  subscription partnerUpdatedSubscription($partnerId: ID!) {
    partnerUpdated(partnerId: $partnerId) {
      new {
        ...PartnerDetailsView
      }
      updated {
        ...PartnerDetailsView
      }
    }
  }
  ${PARTNER_DETAILS_VIEW}
`

export const useGetPartnerQuery = (partnerSlug, options = {}) => {
  const { data, loading, error, refetch, subscribeToMore } = useQuery(getPartner, {
    variables: {
      slug: partnerSlug,
    },
    // Loading state would stay false on slug change otherwise
    notifyOnNetworkStatusChange: true,
    ...options,
  })

  const partner = getValue(data, 'partner')

  useEffect(() => {
    if (partner) {
      return subscribeToMore({
        document: partnerUpdatedSubscription,
        variables: { partnerId: partner.id },
      })
    }
  }, [partner && partner.id])

  return { refetch, partner, loading, error }
}

export const useGetPartnerOrganizationsQuery = (
  partnerSlug,
  options: { [key: string]: any } = {},
) => {
  const filters: { [key: string]: any } = {}
  if (options.search) filters.organizationName = options.search

  const {
    data,
    loading: partnerOrganizationsLoading,
    error: partnerOrganizationsError,
    refetch: partnerOrganizationsRefetch,
  } = useQuery(getPartnerOrganizations, {
    variables: {
      slug: partnerSlug,
      first: options.first,
      offset: options.offset,
      orderBy: options.orderBy,
      filters,
    },
    fetchPolicy: 'cache-and-network',
  })

  const partnerOrganizations = getValue(data, 'partnerOrganizations.nodes', [])
  const partnerOrganizationsTotal = getValue(data, 'partnerOrganizations.recordCount', 0)

  return {
    partnerOrganizations,
    partnerOrganizationsTotal,
    partnerOrganizationsLoading,
    partnerOrganizationsError,
    partnerOrganizationsRefetch,
  }
}

export const useUpdatePartnerMutation = () => {
  const [mutate] = useMutation(updatePartner)

  return async (partner) => {
    let { data } = await mutate({
      variables: { partner },
    })

    return data?.updatePartner
  }
}

export const useUploadPartnerLogo = (partnerId) => {
  const [mutate] = useMutation(uploadPartnerLogo)

  return async (file: File, isDark = false) => {
    let { data } = await mutate({
      context: { hasUpload: true },
      variables: { partnerId, file, isDark },
    })

    return data?.uploadPartnerLogo
  }
}

export const useDeletePartnerLogo = () => {
  const [mutate] = useMutation(deletePartnerLogo)

  return async (partnerId, isDark = false) => {
    return await mutate({
      variables: { partnerId, isDark },
    })
  }
}
