import { makeStyles } from 'tss-react/mui'
import { Theme, useMediaQuery } from '@mui/material'

const useStyles = makeStyles<{ mobile: boolean; isCollapsed: boolean }>()(
  (theme: Theme, { mobile, isCollapsed }) => ({
    sidebar: {
      width: mobile || isCollapsed ? '57px' : '225px', // -1 px border
      backgroundColor: theme.palette.background.paper,
      borderRight: `1px solid ${theme.palette.divider}`,
      gridArea: 'sidebar',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      '& .MuiAccordionSummary-expandIconWrapper svg': {
        height: 20,
        width: 20,
      },
    },
  }),
)

export const Sidebar = ({ children, isCollapsed }: any) => {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const { classes } = useStyles({ mobile, isCollapsed })

  return <div className={classes.sidebar}>{children}</div>
}
